import axios from "../http-client/axios";
import { METALICA_URL } from "../urls/base-urls";
import AlterType from "../../static-data/TypeRequestedFlow";
import requestBuilder from "../request-builder/request-builder";
import {
  mapperToTemporaryAssistanceRequest,
  mapperToTemporaryAssistanceAntecipationRequest,
} from "./temporary-assistance-mapper";
import FormRequestAction from "../../static-data/FormRequestAction";
import ServiceRequestType from "../../static-data/ServiceRequestType";

export async function addTemporaryAssistance(formData, attachments) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToTemporaryAssistanceRequest(formData, FormRequestAction.insert),
      AlterType.save,
      attachments,
      ServiceRequestType.DOCTOR_TEMPORARY_ASSISTENCE
    )
  );
  return resp.data;
}

export async function addATemporaryAssistanceAntecipation(formData) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToTemporaryAssistanceAntecipationRequest(
        formData,
        FormRequestAction.insert
      ),
      AlterType.save,
      [],
      ServiceRequestType.DOCTOR_TEMPORARY_ASSISTENCE_ANTICIPATION
    )
  );
  return resp.data;
}
