<template>
  <b-container fluid>
    <b-row class="justify-content-center my-2">
      <b-card class="custom-card flex-grow-1 my-4 p-5 box-termo ">
        <h5 class="font-weight-bold">
          O Auxílio Temporário pode ser solicitado em três situações:
        </h5>
        <h5 class="mt-4">
          <ul>
            <li>Acidente Pessoal</li>
            <li>Doença</li>
            <li>Licença Maternidade</li>
          </ul>
        </h5>
        <h5 class="font-weight-bold mt-5">
          Entenda o auxílio temporário:
        </h5>
        <h5 class="mt-4">
          O Auxílio Temporário é um valor fixo estipulado pelo Conselho de
          Administração da Cooperativa pago aos médicos com necessidade de se
          afastar das suas atividades.
        </h5>
        <h5 class="mt-5">
          Períodos inferiores a 30 (trinta) dias, o Auxílio Temporário será pago
          proporcionalmente aos dias de afastamento.
        </h5>
        <h5 class="mt-5">
          Terá direito ao Auxílio Temporário o médico que for cooperado há mais
          de 1 (um) ano e em dia com as obrigações perante a Cooperativa.
        </h5>
        <h5 class="mt-5">
          O pagamento do Auxílio Temporário iniciará após o deferimento da
          solicitação do Afastamento Temporário.
        </h5>
        <h5 class="mt-5">
          O Auxílio Temporário será pago pelo período de, no máximo, 90
          (noventa) dias, consecutivos ou não, no período de 12 (doze) meses.
        </h5>
        <h5 class="mt-5">
          Somente poderá formular novo pedido após decorridos 12 (doze) meses,
          contados da data do último pagamento deste benefício.
        </h5>
        <h5 class="mt-5">
          O pagamento do Auxílio Temporário será realizado mensalmente, de
          acordo com o cronograma de pagamento da produção médica. O pagamento
          do auxílio fica sujeito à incidência de todos os tributos impostos
          pela legislação em vigor.
        </h5>
        <h5 class="mt-5">
          O Auxílio Temporário é pessoal, intransferível, impenhorável e
          inalienável.
        </h5>
        <h5 class="font-weight-bold mt-5">
          O pagamento do Auxílio Temporário cessará nos seguintes casos:
        </h5>
        <h5 class="mt-4">
          <ul>
            <li>
              Ao final do período de 90 (noventa) dias, consecutivos ou não,
              computados no período de 12 (doze) meses;
            </li>
            <li>Ao final do período determinado de Afastamento Temporário;</li>
            <li>
              Quando o médico cooperado afastado retomar suas atividades
              remuneradas.
            </li>
          </ul>
        </h5>
        <h5 class="my-5">
          É de exclusiva responsabilidade do médico cooperado comunicar a
          Cooperativa, por escrito, acerca do término de seu estado de
          incapacidade e/ou do seu retorno à atividade remunerada. Além disso, é
          de sua responsabilidade usar da verdade para requerer o benefício.
        </h5>
      </b-card>
    </b-row>

    <TemporaryAssistanceAddModal />
    <TemporaryAssistanceAntecipationAddModal />

    <div>
      <b-row class="mt-5">
        <b-button
          class="my-2 mr-4"
          variant="primary"
          v-b-modal:add-temporary-assistance-modal
        >
          Solicitar auxílio
        </b-button>
        <b-button
          class="my-2"
          variant="primary"
          v-b-modal:add-antecipation-temporary-assistance-modal
        >
          Solicitar antecipação do retorno
        </b-button>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import TemporaryAssistanceAddModal from "../form/TemporaryAssistanceAddModal.vue";
import TemporaryAssistanceAntecipationAddModal from "../form/TemporaryAssistanceAntecipationAddModal.vue";

export default {
  name: "absence-informations",
  components: {
    TemporaryAssistanceAddModal,
    TemporaryAssistanceAntecipationAddModal,
  },
};
</script>

<style lang="scss" scoped>
.marker {
  text-align: left;
  padding: 1rem;
}

.box-termo {
  padding: 1rem;
  max-height: 45rem;
  min-height: 10rem;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
