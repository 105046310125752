<template>
  <div>
    <p class="p4">
      <i class="fas fa-info-circle"></i>
      Importante: Você deve inserir o(s) documento(s) necessário(s) conforme sua
      solicitação.
    </p>
    <b-card class="custom-card my-4" border-variant="light">
      <b-form-row>
        <b-col>
          Intenção do Retorno
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col class="d-flex mb-5">
          <b-form-textarea
            v-model="form.antecipationReturn"
            placeholder="Informe a intenção de retorno"
            size="lg"
            rows="4"
            max-rows="6"
            :state="validateField('antecipationReturn')"
          />
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
import {
  validateField,
  requiredFieldsFilled,
} from "../../../../utils/validate/validate.js";

const DefaultForm = {
  name: "",
  anticipationReturn: null,
};

export default {
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    const setSubmitHandler = inject("setSubmitHandler");
    return { formData, setForm, setSubmitHandler };
  },

  async mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: { ...DefaultForm },
      validations: {},
      requiredFieldsFilled,
      validateField,
    };
  },
  methods: {
    initForm() {
      this.setSubmitHandler(this.onSubmit);
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
      };
    },
    onChangeFormSelect(formData) {
      if (!formData) {
        this.form = { ...DefaultForm };
        this.setForm(this.form);
        this.formData = {
          form: { ...this.form },
          onSubmitHandler: this.onSubmit,
        };
        return;
      }

      const form = {
        ...this.form,
        ...formData,
      };

      this.form = form;
      this.setForm(this.form);
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
      };
    },

    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
  },
};
</script>
