<template>
  <div>
    <AlterFormLayout
      modalId="add-antecipation-temporary-assistance-modal"
      formTitle="Solicitar antecipação do retorno"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar solicitação de antecipação do retorno"
      :onSubmitEvent="onSubmit"
      :disableEvent="this.$store.getters.isAdmin"
    >
      <template #content>
        <TemporaryAssistanceAntecipationForm />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Olá Cooperado(a), acusamos o recebimento de sua solicitação e as tratativas estão em andamento."
      buttonText="Ok, entendi!"
      subdescription="Em breve retornaremos."
      :protocol="this.protocol"
    />
  </div>
</template>

<script>
import TemporaryAssistanceAntecipationForm from "./TemporaryAssistanceAntecipationForm.vue";
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import { addATemporaryAssistanceAntecipation } from "../../../../services/temporary-assistance/temporary-assistance-service";

export default {
  components: {
    AlterFormLayout,
    TemporaryAssistanceAntecipationForm,
    SuccessModal,
  },
  data() {
    return {
      protocol: "",
    };
  },
  methods: {
    async onSubmit(formData) {
      formData.name = "Solicitação de Antecipação do Retorno";
      this.protocol = await addATemporaryAssistanceAntecipation(formData);
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
  },
};
</script>
