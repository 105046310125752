<template>
  <div>
    <p class="p4">
      <i class="fas fa-info-circle"></i>
      Importante: Você deve inserir o(s) documento(s) necessário(s) conforme sua
      solicitação.
    </p>
    <b-card class="custom-card my-4" border-variant="light">
      <b-form-row>
        <b-col>
          MOTIVO DO AFASTAMENTO
        </b-col>
        <b-col>
          <b-form-select
            v-model.lazy.trim="form.temporaryAssistanceMotive"
            :state="validateField('temporaryAssistanceMotive')"
            :options="temporaryAssistanceMotiveOptions"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          Data de Início
        </b-col>
        <b-col>
          <date-picker
            datePickerId="startDate"
            :key="form.startDate"
            :dataValue="form.startDate"
            :state="validateFieldStartDate('startDate')"
            @datePickerChangedValue="onChangedStartDate"
            :required="true"
          />
        </b-col>
        <div
          class="invalid text-invalid-right"
          v-show="validateFieldStartDate('startDate') == false"
        >
          {{ startDateErrorMsg }}
        </div>
      </b-form-row>
      <b-form-row>
        <b-col>
          Data de Término
        </b-col>
        <b-col>
          <date-picker
            datePickerId="endDate"
            :key="form.endDate"
            :dataValue="form.endDate"
            :state="validateFieldEndDate('endDate', 'startDate')"
            @datePickerChangedValue="onChangedEndDate"
            :required="true"
          />
        </b-col>
        <div
          class="invalid text-invalid-right"
          v-show="validateFieldEndDate('endDate', 'startDate') == false"
        >
          {{ endDateErrorMsg }}
        </div>
      </b-form-row>

      <b-form-row>
        <b-col class="d-flex flex-column align-items-start">
          <UploadFile
            @updateFile="eventToUpdateFile"
            :multiple="true"
            :description="'Anexar documento para comprovação'"
            :msgErro="'É necessário inserir um anexo com o comprovante.'"
            :state="validateFieldFile('files')"
            :required="true"
            v-model.lazy.trim="form.files"
          />
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import UploadFile from "../../../../components/upload-file/UploadFile.vue";
import { inject } from "@vue/composition-api";
import { TemporaryAssistanceMotive } from "../../../../static-data/TemporaryAssistanceMotive.js";
import {
  validateField,
  requiredFieldsFilled,
  validateFieldFile,
  validateCheckBox,
  validateFieldStartDate,
  validateFieldEndDate,
} from "../../../../utils/validate/validate.js";
import DatePicker from "../../../../components/date-picker/DatePicker.vue";

const DefaultForm = {
  name: null,
  temporaryAssistanceMotive: null,
  startDate: null,
  endDate: null,
  files: [],
  endDateErrorMsg: "",
  startDateErrorMsg: "",
};

export default {
  components: {
    UploadFile,
    DatePicker,
  },
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    const setSubmitHandler = inject("setSubmitHandler");
    return { formData, setForm, setSubmitHandler };
  },

  async mounted() {
    this.initForm();
  },
  data() {
    return {
      endDateKey: null,
      startDateKey: null,
      formSubmited: false,
      form: { ...DefaultForm },
      validations: {},
      endDateErrorMsg: "",
      startDateErrorMsg: "",
      temporaryAssistanceMotiveOptions: TemporaryAssistanceMotive,
      validateField,
      requiredFieldsFilled,
      validateFieldFile,
      validateCheckBox,
      validateFieldStartDate,
      validateFieldEndDate,
    };
  },
  methods: {
    initForm() {
      this.setSubmitHandler(this.onSubmit);
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
      };
      this.startDateKey = this.form.startDate;
      this.endDateKey = this.form.endDate;
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    onChangeFormSelect(formData) {
      if (!formData) {
        this.form = { ...DefaultForm };
        this.setForm(this.form);
        this.formData = {
          form: { ...this.form },
          onSubmitHandler: this.onSubmit,
        };

        return;
      }

      const form = {
        ...this.form,
        ...formData,
      };

      this.form = form;
      this.setForm(this.form);
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
      };
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
    onChangedStartDate(value) {
      this.form.startDate = value;
    },
    onChangedEndDate(value) {
      this.form.endDate = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-invalid-right {
  width: 100%;
  text-align: right;
}
</style>
