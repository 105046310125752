import { returnDateFormat } from "../../utils/date/date-utils";

export function mapperToTemporaryAssistanceRequest(formData) {
  return {
    "Ação da solicitação": formData.name,
    "Motivo Afastamento": formData.temporaryAssistanceMotive,
    "Data de Início": returnDateFormat(formData.startDate),
    "Data de Término": returnDateFormat(formData.endDate),
  };
}

export function mapperToTemporaryAssistanceAntecipationRequest(formData) {
  return {
    "Ação da solicitação": formData.name,
    "Intenção do Retorno": formData.antecipationReturn,
  };
}
